import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Breadcrumb from "../../../components/BreadCrumb";
import RenderRateManageScreen from "./RenderRateManageScreen";
import SearchingInput from "../../../components/commons/SearchingInput";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import TabSelection from "../../../components/commons/TabSelection";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import { filterServiceLevel } from "../../../helpers/filterServiceLevel";

const rateMenu = [
  {
    title: "Fixed Rate",
    value: "fixed_route_rate",
    display: true,
    tooltipStatus: false,
    toolTipStatusKey: "fixedRates",
    tooltipMessage:
      "Please provide at least one service detail for apply the fixed rate.",
  },
  {
    title: "Distance Rate",
    value: "distance_rate",
    display: true,
    tooltipStatus: true,
    toolTipStatusKey: "distanceRates",
    tooltipMessage:
      "This is required. Please ensure that the distance rate is added for at least one service level",
  },
  {
    title: "Hourly Rate",
    value: "hourly_rule",
    display: true,
    disabled: true,
    tooltipStatus: true,
    toolTipStatusKey: "hourlyRates",
    tooltipMessage: "This does not apply to Booking.com or Ctrip.com.",
  },
  {
    title: "Airport Pickup Fees",
    value: "airport_pickup_fees",
    display: true,
  },
  {
    title: "Lead Time",
    value: "lead_time",
    display: true,
  },
  // {
  //   title: "Lead Time",
  //   value: "add_lead_time",
  //   display: false,
  // },
  // {
  //   title: "Lead Time",
  //   value: "edit_lead_time",
  //   display: false,
  // },
];
const Index = ({ token }) => {
  const navigate = useNavigate();
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();

  const locationState = useLocation();

  const [menuType, setMenuType] = useState("fixed_route_rate");
  const [airportDetail, setAirportDetail] = useState(null);
  const [checkRateModalOpen, setCheckRateModalOpen] = useState(false);
  const [serviceLevelsList, setServiceLevelsList] = useState([]);
  const [rateStatusData, setRateStatusData] = useState({
    fixedRates: false,
    distanceRates: false,
    hourlyRates: false,
    airportPickupFees: false,
    leadTimes: false,
  });

  const [leadTimeModalOpen, setLeadTimeModalOpen] = useState(false);

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: `Manage ${airportIATA}`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },

    {
      name: "Fixed Route Rate",
      // url: "/dashboard/integration/rates/mange-location",
    },
  ];

  const getAirportDetail = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAirportDetail(airportId);
      setAirportDetail(response?.data?.data);
    } catch (error) {
      setAirportDetail(null);
    }
  };

  const getAllServiceLevels = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAllServiceLevels();

      // setServiceLevelsList(response?.data?.data);
      const getResponseData = response?.data?.data;
      if (getResponseData?.length > 0) {
        setServiceLevelsList(
          filterServiceLevel(integrationType, getResponseData)
        );
      }
    } catch (error) {
      setServiceLevelsList([]);
    }
  };

  useEffect(() => {
    getAirportDetail();
    getAllServiceLevels();
    const getStatusData = locationState?.state?.rateStatus;
    if (getStatusData) {
      setRateStatusData({
        ...rateStatusData,
        fixedRates: getStatusData?.fixedRates?.length > 0 ? true : false,
        distanceRates: getStatusData?.distanceRates?.length > 0 ? true : false,
        hourlyRates: getStatusData?.hourlyRates?.length > 0 ? true : false,
        airportPickupFees:
          getStatusData?.airportPickupFees?.length > 0 ? true : false,
        leadTimes: getStatusData?.leadTimes?.length > 0 ? true : false,
      });
    }
  }, []);
  return (
    <div>
      <div className="mt-18 flex justify-content-between">
        <div>
          <Breadcrumb
            paths={[
              {
                url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                name: rateMenu?.filter((menu) => menu.value === menuType)[0]
                  ?.title,
              },
            ]}
            toolTipStatus={true}
          />
          <div className="breadcrumb-description">
            Manage all detail of this location from here.
          </div>
        </div>
        <div className="flex">
          <div>
            <SearchingInput />
          </div>
          {menuType === "lead_time" && (
            <div className="ml-12">
              <MOButtonV2
                text="ADD LEAD TIME"
                textColor="#ffffff"
                radius={4}
                backgroundColor="#29AEE6"
                onClick={() => {
                  setLeadTimeModalOpen(true);
                }}
                height={30}
                fontSize={10}
                padding={"8px 32px"}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-8 ml-6">
        <Breadcrumb
          paths={secondaryPaths?.map((pm, index) => {
            if (index + 1 === secondaryPaths.length) {
              pm.name = rateMenu?.filter(
                (rf) => rf.value === menuType
              )[0]?.title;
            }
            return pm;
          })}
          secondary={true}
        />
      </div>

      {/* Rates menu */}
      <div className="mt-16">
        <div className="flex justify-content-between align-item-center">
          <div>
            <TabSelection
              tooltipStatus={rateStatusData}
              menu={rateMenu?.filter((fm) => fm.display)}
              defaultSelected="Fixed Rate"
              onMenuChange={(tab) => {
                setMenuType(tab?.value);
              }}
            />
          </div>
          <div>
            {menuType === "fixed_route_rate" && (
              <MOButtonV2
                text="VIEW ON MAP"
                textColor="#ffffff"
                radius={4}
                backgroundColor="#29AEE6"
                onClick={() => {
                  navigate(
                    `/dashboard/integration/rates/${integrationType}/${integrationId}/add-polygon`,
                    {
                      state: {
                        integrationType,
                        country,
                        countryId,
                        city,
                        cityId,
                        airportId,
                        iata: airportIATA,
                        centerPoint: {
                          lat: airportDetail?.latitude,
                          lng: airportDetail?.longitude,
                        },
                      },
                    }
                  );
                }}
                height={38}
                fontSize={10}
                padding={"16px 32px"}
              />
            )}
            {menuType === "distance_rate" && (
              <MOButtonV2
                text="CHECK YOUR RATES"
                textColor="#ffffff"
                radius={4}
                backgroundColor="#29AEE6"
                onClick={() => {
                  setCheckRateModalOpen(true);
                }}
                height={38}
                fontSize={10}
                padding={"16px 32px"}
              />
            )}
            {/* {menuType === "lead_time" && (
              <div className="flex align-item-center">
                <div className="mr-12">
                  <MOButtonV2
                    text="ADD LEAD TIME"
                    textColor="#ffffff"
                    radius={4}
                    backgroundColor="#29AEE6"
                    onClick={() => {
                      setMenuType("add_lead_time");
                    }}
                    height={30}
                    fontSize={10}
                    padding={"8px 32px"}
                  />
                </div>
                <div>
                  <MOButtonV2
                    text="EDIT LEAD TIME"
                    textColor="#ffffff"
                    radius={4}
                    backgroundColor="#29AEE6"
                    onClick={() => {
                      setMenuType("edit_lead_time");
                    }}
                    height={30}
                    fontSize={10}
                    padding={"8px 32px"}
                  />
                </div>
              </div>
            )} */}
          </div>
        </div>

        <div>
          <RenderRateManageScreen
            token={token}
            type={menuType}
            setMenuType={setMenuType}
            serviceLevelsList={serviceLevelsList}
            leadTimeModalOpen={leadTimeModalOpen}
            setRateStatusData={setRateStatusData}
            checkRateModalOpen={checkRateModalOpen}
            setLeadTimeModalOpen={setLeadTimeModalOpen}
            setCheckRateModalOpen={setCheckRateModalOpen}
          />
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
