import { RATE_MODULE } from "../constants/rateModule";

export type Field =
  | "rateUpdateStatus"
  | "rateStatusMessage"

export type RateModuleRepo = {
  resetAll: () => any;
  status: {
    statusValue: (field: Field, status: boolean) => any;
    statusMessage: (field: Field,message: string) => any;
  };

};

const RateModule: RateModuleRepo = {
  resetAll: () => ({
    type: RATE_MODULE.RESET_ALL,
    payload: {},
  }),
  status: {
    statusValue: (field, status) => ({
      type: RATE_MODULE.STATUS.VALUE,
      payload: { field, status },
    }),
    statusMessage: (field,message) => ({
      type: RATE_MODULE.STATUS.MESSAGE,
      payload: { field , message},
    }),
  },
 
};

export default RateModule;

export { RateModule };
