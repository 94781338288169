import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import GoogleMapReact from "google-map-react";
import { addLocationValidationSchema } from "../../../validations/rateModuleValidation";
import mobioApi from "../../../services";
import { connect } from "react-redux";
import Location from "../../../redux/actions/local/rate-module";
import { toastMessage } from "../../../helpers/toastMessage";

const EditLocation = ({
  setTabSelection = () => {},
  tabData,
  token,
  companyId,
  editData,
}) => {
  const navigate = useNavigate();
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);

  const handleApiLoaded = (map, maps, radius) => {
    setMapInstance(map);
    setMapsInstance(maps);
    // Create the circle
    new maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map,
      center: { lat: tabData?.latitude, lng: tabData?.longitude },
      radius: radius * 100,
    });
  };
  const zoom = 11;
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        companyId: companyId || "",
        integrationType: tabData?.integrationType || "",
        countryName: tabData?.country || "",
        cityName: tabData?.city || "",
        airportLat: tabData?.latitude || "",
        airportLng: tabData?.longitude || "",
        centralZone: values?.centralZone || "",
        centralZoneLatitude: values?.centralZoneLatitude,
        centralZoneLongitude: values?.centralZoneLongitude,
        leadTime: values?.leadTime,
        airportName: tabData?.name || "",
        supportNumber: values?.supportNumber,
        IATA: tabData?.iata,
        stateName: tabData?.stateName || "",
      };
      const api = mobioApi(token);
      let response = null;
      if (editData?.id) {
        payload.rateId = editData?.associatedCities?.associatedRate?.id;
        response = await api.updateLocation(payload);
      } else {
        response = await api.addNewLocation(payload);
      }

      toastMessage(
        "success",
        response?.data?.msg || "Location Added Successfully"
      );

      // navigate(`/dashboard/integration/rates/${tabData?.integrationType}`);
      navigate(-1);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }
  };
  console.log("editData", editData);
  return (
    <>
      <div>
        <Formik
          initialValues={{
            centralZone: editData ? editData.centralZone : 60,
            centralZoneLongitude: editData
              ? editData.centralZoneLongitude
              : tabData?.longitude || 4.846423,
            centralZoneLatitude: editData
              ? editData.centralZoneLatitude
              : tabData?.latitude || 52.367925,
            leadTime: editData ? editData.leadTime : "",
            supportNumber: editData ? editData.supportNumber : "",
          }}
          validationSchema={addLocationValidationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, isSubmitting, handleChange }) => (
            <Form>
              {/* Setup Polygon */}

              {/* map */}
              <div className="bg-white main-section-spacing border-radius-8">
                {/* <div className="flex ">
                  <h2 className="text-black font-16 font-500">Setup Polygon</h2>
                  <div style={{ marginLeft: "60px" }}>
                    <MOButtonV2
                      text="CREATE POLYGON"
                      textColor="#ffffff"
                      radius={4}
                      height={35}
                      fontSize={10}
                      backgroundColor="#29AEE6"
                      padding={"12px 20px"}
                      onClick={() => {
                        navigate("/dashboard/integration/rates/add-polygon");
                      }}
                    />
                  </div>
                </div> */}

                {/* map */}
                <div className="bg-gray main-section-spacing border-radius-8">
                  <div>
                    <h2 className="text-black font-16 font-500 m-0">
                      How far is your Central Zone?
                    </h2>
                    <p className="text-gray font-13 font-500 m-0 mb-14">
                      How far is your Central Zone?
                    </p>
                  </div>
                  <div className="edit-location-zone-map-section">
                    {/* form */}
                    <div className="bg-white main-section-spacing border-radius-8 zone-map-section-form">
                      <div className="flex gap-16 input_field edit-location-field">
                        <div>
                          <label htmlFor="centralZoneLongitude">
                            Longitude
                          </label>
                          <Field
                            type="text"
                            placeholder="Latitude"
                            id={`centralZoneLongitude`}
                            name={`centralZoneLongitude`}
                          />
                          <ErrorMessage
                            name={`centralZoneLongitude`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div>
                          <label htmlFor="centralZoneLatitude">Latitude</label>
                          <Field
                            type="text"
                            placeholder="Latitude"
                            id={`centralZoneLatitude`}
                            name={`centralZoneLatitude`}
                          />
                          <ErrorMessage
                            name={`centralZoneLatitude`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div>
                          <label htmlFor="centralZone">
                            How far is your Central Zone?
                          </label>
                          <div className="far_central_zone_field">
                            <Field
                              type="number"
                              id={`centralZone`}
                              name={`centralZone`}
                              onChange={(e) => {
                                handleApiLoaded(
                                  mapInstance,
                                  mapsInstance,
                                  values.centralZone
                                );
                               
                                handleChange(e);
                              }}
                            />
                            <ErrorMessage
                              name={`centralZone`}
                              component="div"
                              className="text-danger"
                            />
                            <div className="far_central_zone_field_post_fix">
                              <p>KM</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: "400px", width: "100%" }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: apiKey }}
                        defaultCenter={{
                          lat: tabData?.latitude,
                          lng: tabData?.longitude,
                        }}
                        defaultZoom={zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                          handleApiLoaded(map, maps, values.centralZone)
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* Lead Time */}
                <div className="bg-gray main-section-spacing border-radius-8">
                  <div>
                    <div className="mb-3 input_field">
                      <h2 className="text-black font-16 font-500 m-0">
                        How much notice time do you need in advance to fulfil a
                        booking?
                      </h2>
                      <p className="text-gray font-13 font-500 m-0 mb-14">
                        Lead time
                      </p>
                      <Field
                        type="time"
                        id="leadTime"
                        name="leadTime"
                        className="h-45"
                        onChange={(e) => {
                       
                          handleChange(e);
                        }}
                      />

                      <ErrorMessage
                        name={`leadTime`}
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>

                {/* support number */}
                <div className="bg-gray main-section-spacing border-radius-8">
                  <div className="mb-3 input_field ">
                    <h2 className="text-black font-16 font-500 m-0 mb-14">
                      Support Number
                    </h2>

                    <Field
                      type="text"
                      id={`supportNumber`}
                      name={`supportNumber`}
                      className="h-45"
                      onChange={(e) => {
                        
                        handleChange(e);
                      }}
                    />
                    <ErrorMessage
                      name={`supportNumber`}
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                {/* Create Location Submit Button */}
                <div className="mt-24 flex flex-1 justify-content-between">
                  <div>
                    <MOButtonV2
                      text="BACK"
                      textColor="#ffffff"
                      radius={4}
                      backgroundColor="#C4C4C4"
                      fontSize={10}
                      padding={"12px 33px"}
                      onClick={setTabSelection}
                    />
                  </div>
                  <div>
                    <MOButtonV2
                      type="submit"
                      text="SUBMIT"
                      textColor="#ffffff"
                      radius={4}
                      backgroundColor="#29AEE6"
                      fontSize={10}
                      padding={"12px 33px"}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

const mapState = (state) => ({
  companyId: state.userInfo.user.id,
  token: state.userInfo.token,
});

const mapDispatch = {

};

export default connect(mapState, mapDispatch)(EditLocation);
