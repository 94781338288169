import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/BreadCrumb";
import HeaderDescription from "../../../components/commons/HeaderDescription";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { connect, useDispatch } from "react-redux";
import mobioApi from "../../../services";
import { toastMessage } from "../../../helpers/toastMessage";
import { contactInformationValidationSchema } from "../../../validations/rateModuleValidation";
import ThunksRateModule from "../../../redux/actions/thunks/rateModule";

const Index = ({ token }) => {
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const formik = useRef();
  const dispatch = useDispatch();

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: `Manage ${airportIATA}`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },

    {
      name: "Contact Details",
      // url: "",
    },
  ];

  const getContactDetail = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAirportDetail(airportId);
      const getData = response?.data?.data;
      console.log("getData", getData);
      if (formik?.current && getData) {
        formik.current?.setFieldValue("addressLineOne", getData?.address);
        formik.current?.setFieldValue(
          "addressLineTwo",
          getData?.addressLineTwo
        );
        formik.current?.setFieldValue("city", getData?.city);
        formik.current?.setFieldValue("country", getData?.country);
        formik.current?.setFieldValue("zipCode", getData?.postalCode);
        formik.current?.setFieldValue("firstName", getData?.firstName);
        formik.current?.setFieldValue("lastName", getData?.lastName);
        formik.current?.setFieldValue("landline", getData?.landline);
        formik.current?.setFieldValue("mobile", getData?.phoneNumber);
        formik.current?.setFieldValue("email", getData?.email);
        formik.current?.setFieldValue("genericEmail", getData?.genericEmail);
        formik.current?.setFieldValue(
          "bookingReminder",
          getData?.assignDriverAlert ? "yes" : "no"
        );
        formik.current?.setFieldValue("supportNumber", getData?.supportNumber);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getContactDetail();
  }, []);
  return (
    <>
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb
              paths={[
                {
                  name: "Contact Details",
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                },
              ]}
              toolTipStatus={true}
            />
            <div className="breadcrumb-description">
              View information for this location
            </div>
            <div className="mt-12 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
          </div>
          {/* <div className="flex">
            <MOButtonV2
              text="EDIT CONTACT"
              textColor="#ffffff"
              radius={4}
              backgroundColor="#29AEE6"
              height={30}
              fontSize={11}
              padding={"8.5px 44px"}
            />
          </div> */}
        </div>

        <div>
          {/* form */}
          <div className="width-ful">
            <Formik
              innerRef={formik}
              initialValues={{
                addressLineOne: "",
                addressLineTwo: "",
                city: "",
                country: "",
                zipCode: "",
                firstName: "",
                lastName: "",
                landline: "",
                mobile: "",
                email: "",
                genericEmail: "",
                bookingReminder: "",
                supportNumber: "",
              }}
              validationSchema={contactInformationValidationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const payload = {
                    address: values.addressLineOne,
                    addressLineTwo: values.addressLineTwo,
                    city: values.city,
                    country: values.country,
                    postalCode: values.zipCode,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    landline: values.landline,
                    email: values.email,
                    genericEmail: values.genericEmail,
                    assignDriverAlert: values.bookingReminder === "yes" ? 1 : 0,
                    airportId: airportId,
                    phoneNumber: values.mobile,
                    supportNumber: values.supportNumber,
                  };

                  const api = mobioApi(token);
                  const response = await api.addContactDetail(payload);
                  dispatch(ThunksRateModule.updateStatus(true));
                  toastMessage("success", response?.data?.msg);
                } catch (error) {
                  toastMessage("error", error.response?.data?.msg);
                }
                setSubmitting(false);
              }}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <div>
                    {/* Register address */}
                    <div className="bg-white main-section-spacing-md border-radius-8">
                      <HeaderDescription
                        heading={"Registered address"}
                        description={"Where should we send invoices?"}
                      />

                      <div className="mt-20">
                        {/* Address Line 1 */}
                        <div className="flex align-item-center gap-32">
                          <div className="flex-1">
                            <div className="input-field-md-lg">
                              <label className="">Address Line 1</label>
                              <Field
                                type="text"
                                name="addressLineOne"
                                placeholder="Building name/ Number"
                                className="type-input-focus"
                                value={values.addressLineOne}
                              />
                              <ErrorMessage
                                name="addressLineOne"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                          <div className="flex-1">
                            <div className="input-field-md-lg ">
                              <label className="">Address Line 2</label>
                              <Field
                                type="text"
                                name="addressLineTwo"
                                placeholder="Street/ Location"
                                className="type-input-focus"
                                value={values.addressLineTwo}
                              />
                              <ErrorMessage
                                name="addressLineTwo"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                        </div>
                        {/* City & Country  */}
                        <div className="flex align-item-center gap-32 mt-20">
                          <div className="flex-1">
                            <div className="input-field-md-lg ">
                              <label className="input-field-md-lg">City</label>
                              <Field
                                type="text"
                                name="city"
                                placeholder="City Name"
                                className="type-input-focus"
                                value={values.city}
                              />
                              <ErrorMessage
                                name="city"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                          <div className="flex-1">
                            <div className="input-field-md-lg">
                              <label className="">Country</label>
                              <Field
                                type="text"
                                name="country"
                                placeholder="Entry Name"
                                className="type-input-focus"
                                value={values.country}
                              />

                              <ErrorMessage
                                name="country"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                        </div>
                        {/* Postal/ Zip Code */}
                        <div className="flex align-item-center gap-32 mt-16">
                          <div className="flex-1">
                            <div className="input-field-md-lg">
                              <label className="">Postal/ Zip Code</label>
                              <Field
                                type="text"
                                name="zipCode"
                                placeholder="Zip Code"
                                className="type-input-focus"
                                value={values.zipCode}
                              />

                              <ErrorMessage
                                name="zipCode"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                          <div className="flex-1"></div>
                        </div>
                      </div>
                    </div>

                    {/* Main contact for location */}
                    <div className="bg-white main-section-spacing-md border-radius-8">
                      <HeaderDescription
                        heading={"Main contact for location"}
                        description={
                          "Who should we contact about customer service questions?"
                        }
                      />

                      <div className=" mt-20">
                        {/* First Name & Last name*/}
                        <div className="flex align-item-center gap-32">
                          <div className="flex-1">
                            <div className="input-field-md-lg ">
                              <label className="">First Name</label>
                              <Field
                                type="text"
                                name="firstName"
                                placeholder="Your Name"
                                className="type-input-focus"
                                value={values.firstName}
                              />

                              <ErrorMessage
                                name="firstName"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                          <div className="flex-1">
                            <div className="input-field-md-lg ">
                              <label className="">Last Name</label>
                              <Field
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                className="type-input-focus"
                                value={values?.lastName}
                              />

                              <ErrorMessage
                                name="lastName"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                        </div>
                        {/* Contact   */}
                        <div className="flex align-item-center gap-32 mt-20">
                          <div className="flex-1">
                            <div className="input-field-md-lg ">
                              <label className="">Landline</label>
                              <Field
                                type="text"
                                name="landline"
                                placeholder="Enter Your Landline Number"
                                className="type-input-focus"
                                value={values?.landline}
                              />

                              <ErrorMessage
                                name="landline"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                          <div className="flex-1">
                            <div className="input-field-md-lg ">
                              <label className="">Mobile</label>
                              <Field
                                type="text"
                                name="mobile"
                                placeholder="Enter Your Mobile Number"
                                className="type-input-focus"
                                value={values.mobile}
                              />
                              <ErrorMessage
                                name="mobile"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                        </div>
                        {/* Email */}
                        <div className="flex align-item-center gap-32 mt-20">
                          <div className="flex-1">
                            <div className="input-field-md-lg ">
                              <label className="">Email</label>
                              <Field
                                type="text"
                                name="email"
                                placeholder="Enter Your Main ID"
                                className="type-input-focus"
                                value={values.email}
                              />
                              <ErrorMessage
                                name="email"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                          <div className="flex-1">
                            <div className="input-field-md-lg ">
                              <label className="">Support Number</label>
                              <Field
                                type="text"
                                name="supportNumber"
                                placeholder="Enter Your Support Number"
                                className="type-input-focus"
                                value={values.supportNumber}
                              />
                              <ErrorMessage
                                name="supportNumber"
                                component="p"
                                className="text-danger font-10"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Generic Email Address */}
                    <div className="bg-white main-section-spacing-md border-radius-8">
                      <HeaderDescription
                        heading={"Generic Email Address"}
                        description={
                          "Where should we send details about your bookings?"
                        }
                      />
                      <div className="mt-20">
                        <div
                          className="input-field-md-lg"
                          style={{ width: "48%" }}
                        >
                          <label className=" font-12 mb-6">Email Address</label>
                          <Field
                            type="text"
                            name="genericEmail"
                            placeholder="Email Your Main ID"
                            className="type-input-focus"
                            value={values?.genericEmail}
                          />
                          <ErrorMessage
                            name="genericEmail"
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                      </div>

                      {/* Driver Bookings */}
                      <div className="mt-37">
                        <HeaderDescription
                          heading={
                            "Need a reminder to add drivers to your bookings?"
                          }
                          description={
                            "Any time you receive a booking, we'll send you an alert to assign a driver."
                          }
                        />
                        <div className="max-width-286 mt-20">
                          <div className="flex align-item-center justify-content-between">
                            <label>
                              <Field
                                type="radio"
                                name="bookingReminder"
                                value="yes"
                                checked={values.bookingReminder === "yes"}
                              />
                              <span
                                className="font-14 font-500"
                                style={{
                                  marginLeft: "13px",
                                }}
                              >
                                Yes
                              </span>
                            </label>
                            <label>
                              <Field
                                type="radio"
                                name="bookingReminder"
                                value="no"
                                checked={values.bookingReminder === "no"}
                              />

                              <span
                                className="font-14 font-500"
                                style={{
                                  marginLeft: "13px",
                                }}
                              >
                                No
                              </span>
                            </label>
                            <ErrorMessage
                              name="bookingReminder"
                              component="p"
                              className="text-danger font-10"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* save and cancel button */}
                    <div className="flex align-item-center justify-content-end mt-24">
                      <div className="mr-20 ">
                        <MOButtonV2
                          type="submit"
                          text="SUBMIT"
                          textColor="#ffffff"
                          radius={4}
                          backgroundColor="#29AEE6"
                          height={30}
                          fontSize={11}
                          padding={"8.5px 63px"}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
