export type RateModuleState = {
  rateUpdateStatus:boolean,
  rateStatusMessage:String
};

const rateModuleState: RateModuleState = {
 rateUpdateStatus:false,
 rateStatusMessage:"After updating everything in this area, please visit the main management section at least once to ensure that the bidding rate values are updated for booking bids."
};

export default rateModuleState;
