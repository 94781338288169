import { useEffect, useRef, useState } from "react";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import mobioApi from "../../../../services";
import { useParams } from "react-router";
import { Form, Formik } from "formik";
import { toastMessage } from "../../../../helpers/toastMessage";
import { getCountryCurrency } from "../../../../helpers/getCountryCurrency";
import ThunksRateModule from "../../../../redux/actions/thunks/rateModule";
import { useDispatch } from "react-redux";

const MeetAndGreetFeesForm = ({ countryCurrency, airportId, token }) => {
  const [airportPickupFee, setAirportPickupFee] = useState("");
  const dispatch = useDispatch();
  const [applyToAll, setApplyToAll] = useState(false);

  const formik = useRef();
  const getMeetAndGreetFee = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getMeetAndGreetFee(airportId);
      if (formik?.current) {
        const getPickupList = response?.data?.data?.map((am) => {
          return {
            name: am?.name,
            icon: am?.icon,
            serviceLevelId: am?.id,
            airportId: airportId,
            rate:
              am?.meetAndGreetLevel.length > 0
                ? am?.meetAndGreetLevel[0].rate
                : "",
          };
        });
        formik.current.setFieldValue("pickupList", getPickupList);
      }
    } catch (error) {}
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = values?.pickupList?.filter((pf) => pf.rate);
      const api = mobioApi(token);
      const response = await api.addMeetAndGreetFee(payload);
      dispatch(ThunksRateModule.updateStatus(true));
      toastMessage("success", response?.data?.msg);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }

    // setModalOpen(false);
  };

  useEffect(() => {
    getMeetAndGreetFee();
  }, []);
  return (
    <div>
      <div className="flex align-item-start justify-content-between airport-rate-header-section">
        <div>
          {" "}
          <div className="flex align-item-end ">
            <div className="input_field">
              <label className="font-400 font-16 mb-8">Meet & Greet Fees</label>
              <div>
                <div className="airport-fee-rate-field-all">
                  <div className="airport-fee-rate-field-all-pre-fix">
                    <p className="m-0">{countryCurrency}</p>
                  </div>
                  <div>
                    <input
                      type="number"
                      min={0}
                      step={"0.01"}
                      autoComplete={false}
                      value={airportPickupFee}
                      onChange={(e) => {
                        if (!applyToAll) {
                          setAirportPickupFee(e.target.value);
                        } else {
                          const getUpdateFormikValue =
                            formik?.current?.values?.pickupList?.map((vm) => {
                              vm.rate = e.target.value;
                              return vm;
                            });

                          formik.current.setFieldValue(
                            "pickupList",
                            getUpdateFormikValue
                          );
                          setAirportPickupFee(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mb-6" style={{ marginLeft: "30px" }}>
              <input
                type="checkbox"
                name="toggle"
                // checked={true}
                onChange={(e) => {
                  if (e.target.checked) {
                    setApplyToAll(true);
                    if (formik?.current && airportPickupFee) {
                      const getUpdateFormikValue =
                        formik?.current?.values?.pickupList?.map((vm) => {
                          vm.rate = airportPickupFee;
                          return vm;
                        });

                      formik.current.setFieldValue(
                        "pickupList",
                        getUpdateFormikValue
                      );
                    }
                  } else {
                    setApplyToAll(false);
                  }
                }}
              />
              <label className="ml-8 font-14 font-500">
                Apply fee to all taxi types
              </label>
            </div>
          </div>
        </div>
        <div>
          <div className="airport-fee-notes-section">
            <p className="font-700 font-12 m-0">Meet & Greet</p>

            <p className="font-11 font-500 m-0 mt-16">
              Meet & Greet The driver will go inside to pick-up the customer
              with a name sign. When Meet & Greet service is offered, it will be
              added in addition to the fixed or distance-based route rate.
            </p>
          </div>
        </div>
      </div>
      {/* formik */}
      <div>
        <Formik
          innerRef={formik}
          initialValues={{
            pickupList: [],
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="flex align-item-center justify-content-between">
                <p className="m-0 font-13 font-600">
                  Customise airport pick-up fee
                </p>
                <div>
                  <MOButtonV2
                    type="submit"
                    text="SAVE & UPDATE"
                    textColor="#ffffff"
                    radius={4}
                    backgroundColor="#29AEE6"
                    height={30}
                    fontSize={11}
                    padding={"9px 42px"}
                  />
                </div>
              </div>

              <div
                className="mt-18 "
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {values?.pickupList.map((service, index) => {
                  return (
                    <>
                      <div
                        className="flex align-item-center"
                        style={{
                          width: "50%",
                          marginBottom: "23px",
                        }}
                        key={service?.serviceLevelId}
                      >
                        {/*  */}
                        <div>
                          <img
                            src={service?.icon}
                            className="airport-rate-service-image"
                          />
                        </div>
                        <div>
                          <p className="m-0 font-13 font-500 mb-8">
                            {service?.name}
                          </p>
                          <div className="airport-fee-rate-field">
                            <div className="airport-fee-rate-field-pre-fix">
                              <p className="m-0">{countryCurrency}</p>
                            </div>
                            <div>
                              <input
                                // min={0}
                                autoComplete={false}
                                value={values.pickupList[index].rate}
                                type="number"
                                step={"0.01"}
                                id={service?.serviceLevelId}
                                name={service?.serviceLevelId}
                                onChange={(e) => {
                                  if (!applyToAll) {
                                    setFieldValue(
                                      `pickupList[${index}].rate`,
                                      e.target.value
                                    );
                                  } else {
                                    const getUpdateFormikValue =
                                      formik?.current?.values?.pickupList?.map(
                                        (vm) => {
                                          vm.rate = e.target.value;
                                          return vm;
                                        }
                                      );

                                    formik.current.setFieldValue(
                                      "pickupList",
                                      getUpdateFormikValue
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default MeetAndGreetFeesForm;
