import { useRef, useState } from "react";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { Field, Form, Formik } from "formik";
import { getDistanceUnit } from "../../../../helpers/distanceUnitManage";

const CheckRateModal = ({ modalOpen, setModalOpen, data, countryCurrency,countryData }) => {
  const formik = useRef();

  const handleSubmit = async (values, { setSubmitting }) => {};
  const getInitialData = (id) => {
    const getInitialValue = data.filter((df) => df.id === id);
    if (getInitialValue.length > 0) {
      const getDistanceRateInitial = getInitialValue[0].distanceRatesLevels;
      if (getDistanceRateInitial.length > 0) {
        if (formik?.current) {
          formik.current.setFieldValue(
            "initialRate",
            getDistanceRateInitial[0].initialRate
          );
          formik.current.setFieldValue(
            "initialDistance",
            getDistanceRateInitial[0].initialDistance
          );
        }
      } else {
        if (formik?.current) {
          formik.current.setFieldValue("initialRate", 0);
          formik.current.setFieldValue("initialDistance", 0);
        }
      }
    } else {
      if (formik?.current) {
        formik.current.setFieldValue("initialRate", 0);
        formik.current.setFieldValue("initialDistance", 0);
      }
    }
  };
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={
        <>
          <h1 className="m-0 font-20 font-600">Check your rate</h1>
        </>
      }
      className={"distance-check-rate-modal"}
    >
      <div>
        <Formik
          innerRef={formik}
          initialValues={{
            initialRate: "",
            initialDistance: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div>
                <div className="input-field-v2">
                  <p className="font-500 font-11 mb-6 m-0">Rate Type</p>
                  <Field
                    as="select"
                    name="serviceLevel"
                    onChange={(e) => {
                      getInitialData(e.target.value);
                    }}
                  >
                    <option value="">Select Vehicle</option>
                    {data?.map((service) => (
                      <option key={service.id} value={service.id}>
                        {service?.name}
                      </option>
                    ))}
                  </Field>
                </div>

                {/* Included distance */}
                <div className="mt-18">
                  <h3 className=" m-0 font-11 font-500 mb-6">
                    {" "}
                    Included distance
                  </h3>
                  <div className="distance-model-pre-fix-field distance-rate-check-post  ">
                    <Field
                      type="number"
                      id={`initialDistance`}
                      name={`initialDistance`}
                    />
                    <div className="post-fix-title">
                      <p className="m-0">{getDistanceUnit(countryData?.distanceUnit)}</p>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="check-model-your-rate">
                  <p className="m-0 font-11 font-500 text-gray-500">
                    Your rate
                  </p>
                  <h5 className="m-0 font-15 font-500">
                    {values.initialRate} {countryCurrency}
                  </h5>
                </div>
                {/*  */}
                <div className="check-modal-initial-rate">
                  <p className="m-0 font-11 font-400">
                    Initial Rate (includes {values.initialDistance} {getDistanceUnit(countryData?.distanceUnit)})
                  </p>
                  <p className="m-0 font-11 font-400">
                    {values.initialRate} {countryCurrency}
                  </p>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

export default CheckRateModal;
