import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { Add, ArrowDownward, ArrowUpward } from "@material-ui/icons";
import MOSwitch from "../../../components/commons/MOSwitch";
import DropDown from "../../../components/commons/DropDown";
import SearchingInput from "../../../components/commons/SearchingInput";
import HorizontalIcon from "../../../assets/icons/horizontalIcon.png";
import EditIcon from "../../../assets/icons/editUpdateIcon.png";
import DeleteIcon from "../../../assets/icons/menuDelete.png";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import { formatDate, removeLast00 } from "../../../helpers/getTimeFormat";
import { toastMessage } from "../../../helpers/toastMessage";
import DeleteModal from "../../../components/commons/Modals/DeleteModal";
import { formatAndCapitalize } from "../../../helpers/capitalizeFirstLetter";
import PopoverItem from "../../../components/commons/PopoverItem";

const Index = ({ token }) => {
  const navigate = useNavigate();
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const [anchorEl, setAnchorEl] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [pickupInstructionList, setPickupInstructionList] = useState([]);
  const [singleData, setSingleData] = useState("");

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: `Manage ${airportIATA}`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },

    {
      name: "Pickup Instruction ",
      // url: "",
    },
  ];

  const getAirportDetail = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAirportDetail(airportId);
      const getData = response?.data?.data;
      setPickupInstructionList(getData?.pickupInstructionsAirport);
    } catch (error) {
      setPickupInstructionList([]);
    }
  };

  const deletePickupInstructions = async (data) => {
    try {
      const api = mobioApi(token);
      const response = await api.deletePickupInstructions(data?.id);
      toastMessage("success", response?.data?.msg);
      const getUpdateData = pickupInstructionList?.filter(
        (af) => af.id !== data?.id
      );
      setPickupInstructionList(getUpdateData);
      setSingleData("");
      setDeleteModalOpen(false);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }
  };

  useEffect(() => {
    getAirportDetail();
  }, []);

  useEffect(() => {
    if (!addModalOpen) {
      setSingleData("");
    }
  }, [addModalOpen]);

  return (
    <>
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb
              paths={[
                {
                  name: "Pickup Instruction ",
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                },
              ]}
              toolTipStatus={true}
            />
            <div className="breadcrumb-description">
              Change your distance and fixed route rates for specific events.
            </div>
          </div>
          <div className="flex">
            <div className="mr-12">
              <SearchingInput />
            </div>
            <MOButtonV2
              text="ADD INSTRUCTIONS"
              textColor="#ffffff"
              radius={4}
              backgroundColor="#29AEE6"
              height={30}
              fontSize={11}
              padding={"6.5px 31px"}
              onClick={() => {
                navigate(
                  `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/pickup-instruction/add`
                );
              }}
            />
          </div>
        </div>
        <div className="mt-12 ml-6">
          <Breadcrumb paths={secondaryPaths} secondary={true} />
        </div>
        <div>
          <div className="bg-white  mt-20 border-radius-8">
            <div className="">
              <table className="custom-table-v3 ">
                <thead>
                  <tr>
                    <th>Instruction Name</th>
                    <th className="">Pick-Up Type</th>
                    <th className="">Terminal</th>
                    <th className="">Location</th>
                    <th className="">Start Time</th>
                    <th className="">End Time</th>
                    <th className="">Select Day</th>
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {pickupInstructionList?.length ? (
                    pickupInstructionList.map((row, index) => (
                      <tr key={index}>
                        <td className="text-warp">{row?.name}</td>
                        <td className="text-warp">
                          {formatAndCapitalize(row?.pickupType)}
                        </td>

                        <td style={{ maxWidth: "100px" }}>
                          <PopoverItem
                            names={row.instructionsMeetingPoint?.map(
                              (sm) => sm.terminal
                            )}
                            showItems={1}
                          />
                        </td>

                        <td style={{ maxWidth: "80px" }}>
                          <PopoverItem
                            names={row.instructionsMeetingPoint?.map(
                              (sm) => sm.location
                            )}
                            showItems={1}
                          />
                        </td>
                        <td>{removeLast00(row.startTime)}</td>
                        <td>{removeLast00(row.endTime)}</td>
                        <td>
                          {row?.twentyFourSeven
                            ? "24/7"
                            : formatDate(row?.selectDate)}
                        </td>

                        <td>
                          <DropDown
                            dropDownOpen={anchorEl}
                            setDropDownOpen={setAnchorEl}
                            uniqueId={row.id}
                            IconRender={() => <img src={HorizontalIcon} />}
                            dropDownShape="horizontal"
                            menu={[
                              {
                                onClick: () => {
                                  navigate(
                                    `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/pickup-instruction/add`,
                                    {
                                      state: {
                                        status: "edit",
                                        pickupInstructionData: row,
                                      },
                                    }
                                  );
                                },
                                IconRender: () => <img src={EditIcon} />,
                                title: "Edit",
                              },
                              {
                                onClick: () => {
                                  setSingleData(row);
                                  setDeleteModalOpen(true);
                                },
                                IconRender: () => <img src={DeleteIcon} />,
                                title: "Delete",
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td colSpan="9">
                          <div className="table-no-record-section">
                            No records are found.
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Modals */}
        <DeleteModal
          title="Delete Pick-Up Instruction"
          heading="Are You Sure?"
          description="Are you certain you want to delete this pick-up instruction?"
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          apiCall={() => {
            deletePickupInstructions(singleData);
          }}
        />
      </div>
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
