import React, { useEffect, useRef, useState } from "react";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import VehicleList from "../../../../components/commons/VehicleList";
import mobioApi from "../../../../services";
import { toastMessage } from "../../../../helpers/toastMessage";
import { distanceRateValidationSchema } from "../../../../validations/rateModuleValidation";
import ToolTipMessage from "../../../../components/commons/ToolTipMessage";
import ExclamationIcon from "../../../../assets/icons/manage_heroicons_exclaimation-circle.svg";
import { useDispatch } from "react-redux";
import ThunksRateModule from "../../../../redux/actions/thunks/rateModule";
import { getDistanceUnit } from "../../../../helpers/distanceUnitManage";

const DistanceRateEditModal = ({
  token,
  airportId,
  modalOpen,
  setModalOpen,
  distanceRateList = [],
  serviceLevelsList,
  updateDistanceList,
  selectDefaultService,
  countryCurrency,
  countryData
}) => {
  const [selectServiceLevel, setSelectServiceLevel] = useState(null);
  const dispatch = useDispatch();
  const formik = useRef();
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        serviceLevelId: selectServiceLevel,
        airportId: airportId,
        initialRate: values.initialRate,
        initialDistance: values.initialDistance,
        pricePerKM: values.pricePerKM,
        pricePerMint: values.pricePerMint,
        pricePerMintStatus: false,
        waitPrice: values.waitPrice,
        waitPriceStatus: true,
      };
      const api = mobioApi(token);
      const response = await api.addDistanceRate(payload);
      updateDistanceList(payload);
      dispatch(ThunksRateModule.updateStatus(true));
      toastMessage("success", response?.data?.msg);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }

    // setModalOpen(false);
  };

  const getInitialData = () => {
    const getInitialValue = distanceRateList.filter(
      (df) => df.id === selectServiceLevel
    );

    if (getInitialValue.length > 0) {
      const getDistanceRateInitial = getInitialValue[0]?.distanceRatesLevels;
      if (getDistanceRateInitial.length > 0) {
        if (formik?.current) {
          formik.current.setFieldValue(
            "initialRate",
            getDistanceRateInitial[0].initialRate
          );
          formik.current.setFieldValue(
            "initialDistance",
            getDistanceRateInitial[0].initialDistance
          );
          formik.current.setFieldValue(
            "pricePerKM",
            getDistanceRateInitial[0].pricePerKM
          );
          formik.current.setFieldValue(
            "pricePerMint",
            getDistanceRateInitial[0].pricePerMint
          );
          formik.current.setFieldValue(
            "waitPrice",
            getDistanceRateInitial[0].waitPrice
          );
        }
      } else {
        if (formik?.current) {
          formik.current.setFieldValue("initialRate", "");
          formik.current.setFieldValue("initialDistance", "");
          formik.current.setFieldValue("pricePerKM", "");
          formik.current.setFieldValue("pricePerMint", "");
          formik.current.setFieldValue("waitPrice", "");
        }
      }
    } else {
      if (formik?.current) {
        formik.current.setFieldValue("initialRate", "");
        formik.current.setFieldValue("initialDistance", "");
        formik.current.setFieldValue("pricePerKM", "");
        formik.current.setFieldValue("pricePerMint", "");
        formik.current.setFieldValue("waitPrice", "");
      }
    }
  };

  useEffect(() => {
    getInitialData();
    if (!modalOpen) {
      setSelectServiceLevel(null);
    }
  }, [selectServiceLevel, selectDefaultService]);
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={
        <>
          <div>
            <VehicleList
              defaultValue={selectDefaultService}
              serviceLevelsList={serviceLevelsList}
              setSelectServiceLevel={setSelectServiceLevel}
            />
          </div>
        </>
      }
      className={"distance-rate-edit-modal"}
    >
      <div>
        <Formik
          innerRef={formik}
          initialValues={{
            initialRate: "",
            initialDistance: "",
            pricePerKM: "",
            pricePerMint: "",
            waitPrice: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={distanceRateValidationSchema}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div>
                <h3 className="m-0 mb-8 font-15 font-600">Initial Rate</h3>
                <p className="m-0 font-12 font-400">
                  Please enter the minimum fare you’re willing to accept for a
                  booking and the distance that is included in that price.
                </p>

                <div className="flex gap-16 mt-24">
                  {/* Price */}
                  <div>
                    <h3 className=" m-0 font-11 font-500 mb-6">Rate</h3>
                    <div className="distance-model-pre-fix-field ">
                      <div className="pre-fix-title">
                        <p className="m-0">{countryCurrency}</p>
                      </div>
                      <Field
                        type="number"
                        id={`initialRate`}
                        name={`initialRate`}
                        placeholder="0"
                      />
                    </div>
                    <ErrorMessage
                      name="initialRate"
                      component="p"
                      className="text-danger font-10"
                    />
                  </div>
                  {/* Included distance */}
                  <div>
                    <h3 className=" m-0 font-11 font-500 mb-6">
                      {" "}
                      Initial Distance
                    </h3>
                    <div className="distance-model-pre-fix-field ">
                      <Field
                        type="number"
                        id={`initialDistance`}
                        name={`initialDistance`}
                        placeholder="0"
                      />
                      <div className="post-fix-title">
                        <p className="m-0">{getDistanceUnit(countryData?.distanceUnit)}</p>
                      </div>
                    </div>
                    <ErrorMessage
                      name="initialDistance"
                      component="p"
                      className="text-danger font-10"
                    />
                  </div>
                </div>
              </div>

              {/* Other Rates */}
              <div className="distance-model-other-rates-section">
                <h3 className="m-0 mb-8 font-15 font-600 mt-18">Other Rates</h3>
              </div>

              <div className="flex align-item-center gap-16">
                {/* Price (per KM) */}
                <div>
                  <h3 className=" m-0 font-11 font-500 mb-6">Rate (per {getDistanceUnit(countryData?.distanceUnit)})</h3>
                  <div className="distance-model-pre-fix-field ">
                    <div className="pre-fix-title">
                      <p className="m-0">{countryCurrency}</p>
                    </div>
                    <Field
                      type="number"
                      id={`pricePerKM`}
                      name={`pricePerKM`}
                      placeholder="0"
                    />
                  </div>
                  <ErrorMessage
                    name="pricePerKM"
                    component="p"
                    className="text-danger font-10"
                  />
                </div>
                {/* Price (per Min) */}
                <div>
                  <h3 className=" m-0 font-11 font-500 mb-6">Rate (per Min)</h3>
                  <div className="distance-model-pre-fix-field ">
                    <div className="pre-fix-title">
                      <p className="m-0">{countryCurrency}</p>
                    </div>
                    <Field
                      type="number"
                      id={`pricePerMint`}
                      name={`pricePerMint`}
                      placeholder="0"
                    />
                  </div>
                  <ErrorMessage
                    name="pricePerMint"
                    component="p"
                    className="text-danger font-10"
                  />
                </div>
                {/* Price (for Waiting) */}
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      opacity: 0.6,
                      pointerEvents: "none",
                    }}
                  >
                    <h3 className=" m-0 font-11 font-500 mb-6">
                      Rate (for Waiting)
                    </h3>
                    <div
                      className="distance-model-pre-fix-field "
                      style={{
                        width: "122px",
                      }}
                    >
                      <div className="pre-fix-title">
                        <p className="m-0">{countryCurrency}</p>
                      </div>
                      <Field
                        type="number"
                        id={`waitPrice`}
                        name={`waitPrice`}
                      />
                    </div>
                    <ErrorMessage
                      name="waitPrice"
                      component="p"
                      className="text-danger font-10"
                    />
                  </div>
                  <ToolTipMessage
                    type="primary"
                    content={
                      <>
                        <p className="font-12 font-500">
                          This does not apply to Booking.com or Ctrip.com
                        </p>
                      </>
                    }
                  >
                    <div className="tooltip-icon-position">
                      <div>
                        <img
                          src={ExclamationIcon}
                          alt="ExclamationIcon"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </ToolTipMessage>
                </div>
              </div>

              {/* Create Location Submit Button */}
              <div className="mt-42">
                <MOButtonV2
                  type="submit"
                  // text="CHECK YOUR RATES"
                  text="UPDATE RATES"
                  textColor="#ffffff"
                  radius={4}
                  padding={"11px 38px"}
                  backgroundColor="#29AEE6"
                  fontSize={11}
                />
              </div>
            </Form>
          )}
        </Formik>

        <div></div>
      </div>
    </MOModalWrapper>
  );
};

export default DistanceRateEditModal;
