import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/BreadCrumb";
import HeaderDescription from "../../../components/commons/HeaderDescription";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import DropDownPickupTimings from "../../../components/commons/DropDownPickupTimings";
import TimePickerField from "../../../components/commons/TimePickerField";
import { connect, useDispatch } from "react-redux";
import mobioApi from "../../../services";
import { useEffect, useRef } from "react";
import {
  convertTimeStringToDate,
  formatDate,
  getTimeFormat24Hour,
  removeLast00,
} from "../../../helpers/getTimeFormat";
import { toastMessage } from "../../../helpers/toastMessage";
import DatePickerField from "../../../components/commons/DatePickerField";
import ClockTimer from "../../../components/commons/ClockTimer";
import ThunksRateModule from "../../../redux/actions/thunks/rateModule";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const Index = ({ token }) => {
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const formik = useRef();
  const dispatch = useDispatch();

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: `Manage ${airportIATA}`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },

    {
      name: "Pickup Timings",
      // url: "",
    },
  ];

  const getPickupTimings = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getPickupTimings(airportId);
      const getData = response?.data?.data;
      if (formik?.current && getData) {
        formik.current?.setFieldValue(
          "startTime",
          removeLast00(getData?.startTime)
        );
        // formik.current?.setFieldValue(
        //   "startTime",
        //   convertTimeStringToDate(getData?.startTime)
        // );
        // formik.current?.setFieldValue(
        //   "startDate",
        //   formatDate(getData?.startDate)
        // );
        formik.current?.setFieldValue(
          "startDate",
          new Date(getData?.startDate)
        );
        // formik.current?.setFieldValue(
        //   "endTime",
        //   convertTimeStringToDate(getData?.endTime)
        // );
        formik.current?.setFieldValue(
          "endTime",
          removeLast00(getData?.endTime)
        );
        // formik.current?.setFieldValue("endDate", formatDate(getData?.endDate));
        formik.current?.setFieldValue("endDate", new Date(getData?.endDate));
        // date
        const daysData = [];
        Object.keys(getData)?.forEach((of) => {
          if (daysOfWeek?.filter((df) => df.toLowerCase() === of).length > 0) {
            const capitalizedDay =
              of.charAt(0).toUpperCase() + of.slice(1).toLowerCase();
            daysData.push({ day: capitalizedDay, value: getData[of] });
          }
        });
        if (daysData?.length > 0) {
          formik.current?.setFieldValue("daysSelection", daysData);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPickupTimings();
  }, []);

  return (
    <>
      {/* Redesign start */}
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb
              paths={[
                {
                  name: "Pickup Timings",
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                },
              ]}
              toolTipStatus={true}
            />
            <div className="breadcrumb-description">
              Use this area to manage when you’re available to pick up
              passengers
            </div>
            <div className="mt-8 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
          </div>
        </div>

        <div className="bg-white border-radius-8 mt-20 pickup-timing-section">
          {/* Header */}
          <HeaderDescription
            heading={"Your pick-up availability for this location"}
            description={"Choose the hours you are available to pick up rides."}
          />
          {/* Formik */}
          <Formik
            innerRef={formik}
            initialValues={{
              // startTime: convertTimeStringToDate("00:00:00"),
              startTime: "00:00",
              startDate: new Date(),
              // endTime: convertTimeStringToDate("00:00:00"),
              endTime: "00:00",
              endDate: new Date(),
              daysSelection: daysOfWeek.map((day) => ({
                day,
                value: "24Hours",
              })),
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const days = values?.daysSelection?.reduce((acc, current) => {
                  acc[current.day.toLowerCase()] = current.value;
                  return acc;
                }, {});
                const payload = {
                  startTime: getTimeFormat24Hour(values.startTime),
                  startDate: values.startDate,
                  endTime: getTimeFormat24Hour(values.endTime),
                  endDate: values.endDate,
                  ...days,
                };
                const api = mobioApi(token);
                const response = await api.addPickupTimings(airportId, payload);
                dispatch(ThunksRateModule.updateStatus(true));
                toastMessage("success", response?.data?.msg);
              } catch (error) {
                toastMessage("error", error.response?.data?.msg);
              }
              setSubmitting(false);
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <div>
                  <div className="flex align-item-center mt-34">
                    {/* Start time */}
                    <div className=" max-w-245 ">
                      <p className="font-14 font-400 mb-6">Start Time</p>
                      {/* <Field
                        name="startTime"
                        className="pickup-time-picker"
                        component={TimePickerField}
                        value={values.startTime}
                      /> */}

                      <Field
                        name="startTime"
                        render={({ field, form }) => {
                          return (
                            <ClockTimer
                              field={field}
                              form={form}
                              style={{
                                height: "32px",
                              }}
                              iconStyle={{
                                height: "16px",
                                width: "16px",
                                cursor: "pointer",
                                marginRight: "4px",
                              }}
                            />
                          );
                        }}
                      />
                      <ErrorMessage name="startTime" component="div" />
                    </div>
                    {/* Start Date */}
                    <div className="input_field max-w-245 ml-16">
                      <p className="font-14 font-400 mb-6"> Start Date</p>
                      {/* <Field
                        type="date"
                        name="startDate"
                        style={{ textIndent: "4px", paddingRight: "4px" }}
                      /> */}
                      <DatePickerField
                        placeholder="Select Date"
                        name="startDate"
                        value={values?.startDate}
                        onChange={(value) => setFieldValue("startDate", value)}
                      />
                      <ErrorMessage name="startDate" component="div" />
                    </div>
                    {/* End Time */}
                    <div className=" max-w-245 ml-32">
                      <p className="font-14 font-400 mb-6">End time</p>
                      {/* <Field
                        name="endTime"
                        className="pickup-time-picker"
                        component={TimePickerField}
                        value={values.endTime}
                      /> */}
                      <Field
                        name="endTime"
                        render={({ field, form }) => {
                          return (
                            <ClockTimer
                              field={field}
                              form={form}
                              style={{
                                height: "32px",
                              }}
                              iconStyle={{
                                height: "16px",
                                width: "16px",
                                cursor: "pointer",
                                marginRight: "4px",
                              }}
                            />
                          );
                        }}
                      />
                      <ErrorMessage name="endTime" component="div" />
                    </div>
                    {/* End Date */}
                    <div className="input_field max-w-245 ml-16">
                      <p className="font-14 font-400 mb-6">End Date</p>
                      {/* <Field
                        type="date"
                        name="endDate"
                        style={{ textIndent: "4px", paddingRight: "4px" }}
                      /> */}
                      <DatePickerField
                        placeholder="Select Date"
                        name="endDate"
                        value={values?.endDate}
                        onChange={(value) => setFieldValue("endDate", value)}
                      />
                      <ErrorMessage name="endDate" component="div" />
                    </div>
                  </div>
                  {/* middle header */}
                  <div className="mt-50">
                    <HeaderDescription
                      heading={"Select days"}
                      description={
                        "Use the select boxes to select a day and apply the pick-up times"
                      }
                    />
                  </div>
                  {/* days sections */}
                  <div className="mt-34">
                    <FieldArray
                      name="daysSelection"
                      render={(arrayHelpers) => (
                        <div className="mt-18">
                          {values.daysSelection.map((day, index) => (
                            <div
                              key={index}
                              className="input_field flex align-item-center mb-16"
                            >
                              <div className="w-341">
                                <p className="font-15 font-500"> {day.day}</p>
                              </div>
                              <div className="width-full pick-time-days-field">
                                <DropDownPickupTimings
                                  name={`daysSelection.${index}.value`}
                                  value={
                                    values.daysSelection[index].value
                                      ? {
                                          value:
                                            values.daysSelection[index].value,
                                          text: values.daysSelection[index]
                                            .value,
                                        }
                                      : null
                                  }
                                  handleOnChange={(selectedOption) => {
                                    setFieldValue(
                                      `daysSelection.${index}.value`,
                                      selectedOption.value
                                    );
                                    arrayHelpers.replace(index, {
                                      ...day,
                                      value: selectedOption.value,
                                    });
                                  }}
                                />
                                <ErrorMessage
                                  name={`daysSelection.${index}.value`}
                                  component="div"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />

                    <div className="flex align-item-center justify-content-end mt-24">
                      <div>
                        <MOButtonV2
                          text="SUBMIT"
                          type="submit"
                          textColor="#ffffff"
                          radius={4}
                          backgroundColor="#29AEE6"
                          height={30}
                          widthSize="168px"
                          fontSize={10}
                          padding={"8px 12px 8px 12px"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/* Redesign end */}
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
