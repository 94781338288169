import React, { useState, useEffect, Suspense } from "react";
import MaterialTable from "material-table";
import {
  Grid,
  Paper,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table as MUITable,
  Select,
  MenuItem,
  Input,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRight from "@material-ui/icons/ArrowRight";
import moment from "moment";
import _ from "lodash";
import Image from "react-image-enlarger";
import ThunksOnboardingListing from "../../../redux/actions/thunks/onboarding-listing";
import { connect } from "react-redux";
import RejectMessageModal from "./RejectMessageModal";
import { ThunksApprove } from "../../../redux/actions/thunks/onboarding-verification";
import tableIcons from "../VehicleListing/constants";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";
import DisplayName from "./DisplayName";
import ThunksDrivers from "../../../redux/actions/thunks/drivers";
import { Img } from "react-image";

const useStyles = makeStyles((theme) => ({
  main_dropdown: {
    marginTop: 20,
  },
  image: {
    width: 100,
    height: "auto",
    marginRight: 20,
  },
  approveButton: { backgroundColor: theme.palette.primary.main },

  user_info: {
    marginLeft: 24,
  },
  loadingWrapper: {
    paddingTop: "0.06rem",
    paddingBottom: "0.06rem",
  },
}));

function SingleSource({ src, title }) {
  const [zoomed, setZoomed] = React.useState(false);

  return (
    <Suspense>
      {zoomed ? (
        <div style={{ marginRight: 16 }}>
          <span>{title}</span>
          <Image
            style={{ width: "150px", height: "150px" }}
            zoomed={zoomed}
            src={src}
            onClick={() => setZoomed(true)}
            onRequestClose={() => setZoomed(false)}
          />
        </div>
      ) : (
        <div style={{ marginRight: 16 }}>
          <span>{title}</span>
          <Img
            style={{ width: "150px", height: "150px" }}
            zoomed={zoomed}
            src={src}
            onClick={() => setZoomed(true)}
            onRequestClose={() => setZoomed(false)}
          />
        </div>
      )}
    </Suspense>
  );
}
function Table({
  getOnboardingLisitng,
  response,
  loading,
  approveOnboarding,
  filter,
  errorStatus,
  success,
  reset,
  updateOnboardingListing,
  changeDriversRole,
  orderFilter,
}) {
  const [years, setYears] = useState([]);
  const [seats, setSeats] = useState([]);
  useEffect(() => {
    getOnboardingLisitng(orderFilter);

    const allYears = [];

    for (let year = 2000; year <= new Date().getFullYear(); year++) {
      allYears.push(year);
    }
    setYears(allYears);

    const allSeats = [];

    for (let seat = 1; seat <= 8; seat++) {
      allSeats.push(seat);
    }
    setSeats(allSeats);
  }, []);
  useEffect(() => {
    getOnboardingLisitng(orderFilter);
  }, [filter, orderFilter]);

  useEffect(() => {
    loading && getOnboardingLisitng(orderFilter);
  }, [loading]);
  const classes = useStyles();

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1) || "";
  }
  const commonCellStyle = {
    fontSize: 12,
    fontWieght: 700,
  };

  const updateOnBoarding = async (vehicle) => {
    await updateOnboardingListing(vehicle);
    await getOnboardingLisitng(orderFilter);
  };

  const handleInsuranceExpiry = (event, rowData) => {
    rowData.insuranceExpiry = event.target.value;
  };

  const handleLicenseExpiry = (event, rowData) => {
    rowData.licenseExpiry = event.target.value;
  };

  const handleVehicleExpiry = (event, rowData) => {
    rowData.vehicleCardExpiry = event.target.value;
  };

  const handleCategoryChange = (event, rowData) => {
    rowData.car.category = event.target.value;
  };

  const handleSeatingCapacityChange = (event, rowData) => {
    rowData.car.seatingCapacity = event.target.value;
  };

  const handleYearChange = (event, rowData) => {
    rowData.car.year = event.target.value;
  };

  const handleNumberPlateChange = (event, rowData) => {
    rowData.numberPlate = event.target.value;
  };

  const handleColorChange = (event, rowData) => {
    rowData.car.color = event.target.value;
  };

  return (
    <>
      <MaterialTable
        isLoading={loading}
        data={response.filter((i) => i.verify == filter)}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={tableIcons}
        columns={[
          {
            title: "Actions",
            render: (rowData) => {
              switch (rowData.verify) {
                case "Approved":
                  return <></>;

                case "Pending":
                  return (
                    <>
                      <Button
                        fullWidth
                        className={classes.approveButton}
                        onClick={() => approveOnboarding(rowData)}
                      >
                        Approve
                      </Button>
                      <RejectMessageModal id={rowData.id} />
                    </>
                  );

                case "Rejected":
                  return;
                  <></>;
              }
            },
          },
          {
            cellStyle: { ...commonCellStyle },
            title: "Display Name",
            render: (rowData) => {
              const setDisplayName = (value) => {
                rowData.user.displayName = value;
              };
              if (rowData.verify == "Approved" || rowData.verify == "Pending") {
                return (
                  <DisplayName
                    displayName={rowData.user.displayName}
                    setDisplayName={setDisplayName}
                  />
                );
              } else if (rowData.verify == "Rejected") {
                return rowData.user.displayName;
              }
            },
          },
          {
            cellStyle: { ...commonCellStyle },
            title: "Name",
            field: "user.fullName",
            editable: "never",
          },
          {
            cellStyle: { ...commonCellStyle },
            title: "Role",
            field: "user.role",
            editable: "never",
          },
          {
            cellStyle: { ...commonCellStyle },
            title: "Verification",
            field: "verify",
            editable: "never",
          },
          {
            cellStyle: { ...commonCellStyle },
            title: "City",
            field: "user.city",
            editable: "never",
          },
          {
            cellStyle: { ...commonCellStyle },
            title: "Email",
            field: "user.email",
            editable: "never",
          },
          {
            cellStyle: { ...commonCellStyle },
            title: "Phone #",
            field: "user.phoneNumber",
            editable: "never",
          },
          {
            cellStyle: { ...commonCellStyle },
            title: "Postal Code",
            field: "user.postalCode",
            editable: "never",
          },
          {
            title: "Active City",
            render: (rowData) => (
              <span>{capitalizeFirstLetter(rowData.user.activeCity)}</span>
            ),
          },
          {
            title: "Register Date",
            render: (rowData) => (
              <span>{moment(rowData.createdAt).format("YYYY-MM-DD")}</span>
            ),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Onboardings"
        detailPanel={[
          {
            icon: ArrowRight,
            tooltip: "Show Details",
            render: (rowData) => {
              console.log("Row Data:", rowData);
              return (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h4>Vehicle Details</h4>
                    <TableContainer component={Paper}>
                      <MUITable aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>BrandName</TableCell>
                            <TableCell>CarName</TableCell>
                            <TableCell>Number Plate</TableCell>
                            <TableCell>Color</TableCell>
                            <TableCell>Year</TableCell>

                            <TableCell>Category</TableCell>

                            <TableCell>Seating Capacity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            key={rowData.id}
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {rowData.brand.brandName}
                            </TableCell>
                            <TableCell>{rowData.car.carName}</TableCell>
                            <TableCell sx>
                              {rowData.verify !== "Pending" &&
                              rowData.verify !== "Approved" ? (
                                rowData.numberPlate
                              ) : (
                                <Input
                                  defaultValue={rowData.numberPlate}
                                  style={{ maxWidth: 100 }}
                                  label="numberPlate"
                                  labelId="numberPlate"
                                  id="numberPlate"
                                  onChange={(e) =>
                                    handleNumberPlateChange(e, rowData)
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell sx>
                              {rowData.verify !== "Pending" &&
                              rowData.verify !== "Approved" ? (
                                rowData.color
                              ) : (
                                <Input
                                  defaultValue={rowData.car.color}
                                  style={{ maxWidth: 100 }}
                                  label="Color"
                                  labelId="Color"
                                  id="Color"
                                  onChange={(e) =>
                                    handleColorChange(e, rowData)
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {rowData.verify !== "Pending" &&
                              rowData.verify !== "Approved" ? (
                                rowData.car.year
                              ) : (
                                <Select
                                  labelId="year"
                                  id="year"
                                  defaultValue={rowData.car.year}
                                  label="year"
                                  onChange={(e) => handleYearChange(e, rowData)}
                                >
                                  {years.map((year) => (
                                    <MenuItem value={year}>{year}</MenuItem>
                                  ))}
                                </Select>
                              )}
                            </TableCell>
                            <TableCell>
                              {rowData.verify !== "Pending" &&
                              rowData.verify !== "Approved" ? (
                                rowData.car.category
                              ) : (
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  defaultValue={rowData.car.category}
                                  label="Age"
                                  onChange={(e) =>
                                    handleCategoryChange(e, rowData)
                                  }
                                >
                                  <MenuItem value="STANDARD">STANDARD</MenuItem>
                                  <MenuItem value="EXECUTIVE">
                                    EXECUTIVE
                                  </MenuItem>
                                  <MenuItem value="ELECTRIC">ELECTRIC</MenuItem>
                                  <MenuItem value="MINI_BUS">MINI BUS</MenuItem>
                                  <MenuItem value="CARRIER">CARRIER</MenuItem>
                                  <MenuItem value="LARGE_CARRIER">
                                    LARGE CARRIER
                                  </MenuItem>
                                  <MenuItem value="EXE_CARRIER">
                                    EXE CARRIER
                                  </MenuItem>
                                  <MenuItem value="LUXURY">LUXURY</MenuItem>

                                  <MenuItem value="ELECTRIC_LUXURY">
                                    ELECTRIC LUXURY
                                  </MenuItem>
                                  <MenuItem value="ELECTRIC_PEOPLE_CARRIER">
                                    ELECTRIC PEOPLE CARRIER
                                  </MenuItem>
                                  <MenuItem value="ELECTRIC_EXE_PEOPLE_CARRIER">
                                    ELECTRIC EXE PEOPLE CARRIER
                                  </MenuItem>
                                  <MenuItem value="ELECTRIC_LARGE_PEOPLE_CARRIER">
                                    ELECTRIC LARGE PEOPLE CARRIER
                                  </MenuItem>
                                </Select>
                              )}
                            </TableCell>
                            <TableCell>
                              {rowData.verify !== "Pending" &&
                              rowData.verify !== "Approved" ? (
                                rowData.car.seatingCapacity
                              ) : (
                                <Select
                                  labelId="seatingCapacity"
                                  id="seatingCapacity"
                                  defaultValue={rowData.car.seatingCapacity}
                                  label="seatingCapacity"
                                  onChange={(e) =>
                                    handleSeatingCapacityChange(e, rowData)
                                  }
                                >
                                  {seats.map((seat) => (
                                    <MenuItem value={seat}>{seat}</MenuItem>
                                  ))}
                                </Select>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </MUITable>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: "25px" }}>
                    <h4>Profile Picture</h4>
                    {/* <p style={{ fontSize: 12, fontWeight: "bold" }}>
                    {`${rowData.Brand.brandName} - ${rowData.car.carName} (${rowData.car.year})`}
                  </p> */}
                    <div style={{ display: "flex" }}>
                      {rowData.user.imageUrl ? (
                        <SingleSource src={rowData.user.imageUrl} />
                      ) : (
                        <img
                          src="https://ralfvanveen.com/wp-content/uploads/2021/06/Placeholder-_-Glossary.svg" // Provide the URL for the placeholder image
                          alt="Placeholder"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }} // Adjust width, height, and other styles as needed
                        />
                      )}
                    </div>
                  </Grid>

                  {/* <Grid item xs={6}>
                     <p style={{ fontSize: 12, fontWeight: "bold" }}>
                      {`${rowData.Brand.brandName} - ${rowData.car.carName} (${rowData.car.year})`}
                    </p> 
                    <h4>Pics of Vehicle</h4>
                    <div style={{ display: "flex" }}>
                      <SingleSource src={rowData.pictureBack} />
                      <SingleSource src={rowData.pictureFront} />
                      <SingleSource src={rowData.pictureSide} />
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: "25px" }}>
                    <h4>Vehicle Card</h4>
                    <p style={{ fontSize: 12, fontWeight: "bold" }}>
                      {rowData.verify === "Rejected" && "Expiry Date: "}
                      <span style={{ fontWeight: "normal" }}>
                        {rowData.verify !== "Rejected" ? (
                          <TextField
                            id="vehicleExpiry"
                            label="Expiry Date"
                            type="date"
                            defaultValue={moment(
                              rowData.vehicleCardExpiry
                            ).format("YYYY-MM-DD")}
                            sx={{ width: 220 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => handleVehicleExpiry(e, rowData)}
                          />
                        ) : (
                          moment(rowData.vehicleCardExpiry).format("DD-MM-YYYY")
                        )}
                      </span>
                    </p>
                    <div style={{ display: "flex" }}>
                      <SingleSource src={rowData.vehicleCardFront} />
                      <SingleSource src={rowData.vehicleCardBack} />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <h4>License Card</h4>
                    <p style={{ fontSize: 12, fontWeight: "bold" }}>
                      {rowData.verify === "Rejected" && "Expiry Date : "}
                      <span style={{ fontWeight: "normal" }}>
                        {rowData.verify !== "Rejected" ? (
                          <TextField
                            id="licenseExpiry"
                            label="Expiry Date"
                            type="date"
                            defaultValue={moment(rowData.licenseExpiry).format(
                              "YYYY-MM-DD"
                            )}
                            sx={{ width: 220 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => handleLicenseExpiry(e, rowData)}
                          />
                        ) : (
                          moment(rowData.licenseExpiry).format("DD-MM-YYYY")
                        )}
                      </span>
                    </p>
                    <div style={{ display: "flex" }}>
                      <SingleSource src={rowData.licenseFront} />
                      <SingleSource src={rowData.licenseBack} />
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: "25px" }}>
                    <h4>Insurance Document</h4>
                    <p style={{ fontSize: 12, fontWeight: "bold" }}>
                      {rowData.verify === "Rejected" && "Expiry Date: "}
                      <span style={{ fontWeight: "normal" }}>
                        {rowData.verify !== "Rejected" ? (
                          <TextField
                            id="insuranceExpiry"
                            label="Expiry Date"
                            type="date"
                            defaultValue={moment(
                              rowData.insuranceExpiry
                            ).format("YYYY-MM-DD")}
                            sx={{ width: 220 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => handleInsuranceExpiry(e, rowData)}
                          />
                        ) : (
                          moment(rowData.insuranceExpiry).format("DD-MM-YYYY")
                        )}
                      </span>
                    </p>
                    <SingleSource src={rowData.insuranceImage} />
                  </Grid> */}

                  {rowData.network?.networkDocs.map((docTemplate) => {
                    const thisVal = rowData?.onboardingDocs.find(
                      (obd) => obd.networkDocId == docTemplate.id
                    )?.data;
                    let valObj = !!thisVal ? JSON.parse(thisVal) : {};
                    try{
                        if(typeof valObj == "string"){
                          valObj = JSON.parse(valObj)
                        }
                    }catch {}
                    return (
                      <Grid item xs={6} style={{ paddingLeft: "25px" }}>
                        <h4>{docTemplate.name}</h4>
                        {docTemplate.type == "Image" &&
                          (docTemplate.imageType == "Single" ? (
                            <SingleSource src={valObj?.image} />
                          ) : docTemplate.imageType == "FrontBack" ? (
                            <>
                              <SingleSource src={valObj?.front} />
                              <SingleSource src={valObj?.back} />
                            </>
                          ) : docTemplate.imageType == "FrontBackSide" ? (
                            <>
                              <SingleSource src={valObj?.front} />
                              <SingleSource src={valObj?.back} />
                              <SingleSource src={valObj?.side} />
                            </>
                          ) : (
                            ""
                          ))}
                        {docTemplate.type == "Text" &&
                          (valObj?.value ? (
                            <p style={{ fontSize: 12 }}>{valObj?.value}</p>
                          ) : (
                            <p style={{ color: "#E57373" }}>No File Added</p>
                          ))}
                        {docTemplate.type == "File" &&
                          (valObj?.file ? (
                            <a
                              href={valObj?.file}
                              download={true}
                              style={{
                                margin: "5px",
                                display: "inline-block",
                                padding: "5px",
                                color: "black",
                                backgroundColor: "#ADD8E6",
                                borderRadius: "2px",
                                textDecoration: "none",
                              }}
                            >
                              Download
                            </a>
                          ) : (
                            <p style={{ color: "#E57373" }}>No File Added</p>
                          ))}
                        <p style={{ fontSize: 12, fontWeight: "bold" }}>
                          {docTemplate.expiryIncluded &&
                            rowData.verify === "Rejected" &&
                            "Expiry Date: "}
                          <span style={{ fontWeight: "normal" }}>
                            {docTemplate.expiryIncluded &&
                            rowData.verify !== "Rejected" ? (
                              <TextField
                                id="insuranceExpiry"
                                label="Expiry Date"
                                type="date"
                                defaultValue={moment(
                                  valObj.expiry
                                ).format("YYYY-MM-DD")}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) =>
                                  handleInsuranceExpiry(e, rowData)
                                }
                              />
                            ) : (
                              moment(valObj.expiry).format("DD-MM-YYYY")
                            )}
                          </span>
                        </p>
                      </Grid>
                    );
                  })}

                  {rowData.verify !== "Rejected" && (
                    <Grid item xs={12}>
                      <Button
                        style={{
                          margin: "5px",
                          position: "relative",
                          left: "30%",
                          backgroundColor: "purple",
                          color: "white",
                        }}
                        onClick={() => {
                          changeDriversRole(rowData.user.id, {
                            displayName: rowData.user.displayName,
                            role: rowData.user.role,
                          });
                          updateOnBoarding(rowData);
                        }}
                      >
                        Update
                      </Button>
                    </Grid>
                  )}
                </Grid>
              );
            },
          },
        ]}
      />

      {errorStatus && (
        <ErrorSnack reset={() => reset()} errorMsg={errorStatus} />
      )}
      {success && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Onboarding Approved"}
        />
      )}
    </>
  );
}

const mapState = (state) => ({
  response: state.onboardingListing.response.data,
  loading: state.onboardingListing.loading,
  errorStatus: state.approve.error.message,
  success: state.approve.response.status,
});

const mapDispatch = {
  getOnboardingLisitng: ThunksOnboardingListing.OnboardingListing,
  updateOnboardingListing: ThunksOnboardingListing.updateOnboardingListing,
  approveOnboarding: ThunksApprove.ApproveOnboarding,
  reset: Local.ApproveOnboarding.api.reset,
  changeDriversRole: ThunksDrivers.Role,
};

export default connect(mapState, mapDispatch)(Table);
