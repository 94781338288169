

const RATE_MODULE = {
  RESET_ALL: "ACTION.LOCAL.CREATE_LOCATION.RESET_ALL",
  STATUS: {
    VALUE: "ACTION.LOCAL.CREATE_LOCATION.FIELD.UPDATE",
    MESSAGE: "ACTION.LOCAL.CREATE_LOCATION.FIELD.CLEAR",
  },


};

export { RATE_MODULE };
