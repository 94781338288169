import React, { useEffect, useRef, useState } from "react";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import TimePickerField from "../../../../components/commons/TimePickerField";
import {
  convertTimeStringToDate,
  getTimeFormat24Hour,
  removeLast00,
} from "../../../../helpers/getTimeFormat";
import mobioApi from "../../../../services";
import { toastMessage } from "../../../../helpers/toastMessage";
import { serviceLevelValidationSchema } from "../../../../validations/rateModuleValidation";
import ExclamationIcon from "../../../../assets/icons/manage_heroicons_exclaimation-circle.svg";
import ToolTipMessage from "../../../../components/commons/ToolTipMessage";
import ClockTimer from "../../../../components/commons/ClockTimer";

const ServiceLevelModal = ({
  modalOpen,
  setModalOpen,
  serviceLevelList,
  token,
  airportId,
  updateList,
  defaultService,
  rateModuleUpdateStatus,
}) => {
  const [initialLoadData, setInitialLoadData] = useState("");
  const formik = useRef();
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        airportId: values.airportId || airportId,
        serviceLevelId: values.serviceLevelId,
        leadTime: getTimeFormat24Hour(values.leadTime),
        passenger: values.passenger,
        suitcases: values.suitcases,
        idleTime: getTimeFormat24Hour(values.idleTime),
        rate: "0",
        cancellationTime: getTimeFormat24Hour(values.cancellationTime),
        applyToAll: values.applyToAll,
      };
      const api = mobioApi(token);
      const response = await api.addAirportServiceLevels(payload);
      updateList(payload);
      rateModuleUpdateStatus(true);
      toastMessage("success", response?.data?.msg);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }

    // setModalOpen(false);
  };

  const setInitialData = (selectServiceLevel) => {
    const getInitialValue = serviceLevelList?.filter(
      (df) => df.id === selectServiceLevel
    );

    if (getInitialValue.length > 0) {
      const getAirportServiceLevelInitial =
        getInitialValue[0]?.associatedServiceLevel;
      if (getAirportServiceLevelInitial.length > 0) {
        if (formik?.current) {
          formik.current.setFieldValue(
            "leadTime",
            removeLast00(getAirportServiceLevelInitial[0].leadTime)
          );
          // formik.current.setFieldValue(
          //   "leadTime",
          //   convertTimeStringToDate(getAirportServiceLevelInitial[0].leadTime)
          // );
          formik.current.setFieldValue(
            "idleTime",
            convertTimeStringToDate(getAirportServiceLevelInitial[0].idleTime)
          );
          formik.current.setFieldValue(
            "cancellationTime",
            convertTimeStringToDate(
              getAirportServiceLevelInitial[0].cancellationTime
            )
          );
          formik.current.setFieldValue(
            "passenger",
            getAirportServiceLevelInitial[0]?.passenger
          );
          formik.current.setFieldValue(
            "suitcases",
            getAirportServiceLevelInitial[0]?.suitcases
          );
          formik.current.setFieldValue(
            "rate",
            getAirportServiceLevelInitial[0]?.rate?.toString()
          );
          formik.current.setFieldValue("applyToAll", false);
        }
      } else {
        if (formik?.current) {
          // formik.current.setFieldValue(
          //   "leadTime",
          //   convertTimeStringToDate("00:00:00")
          // );
          formik.current.setFieldValue("leadTime", "00:00");
          formik.current.setFieldValue(
            "idleTime",
            convertTimeStringToDate("00:00:00")
          );
          formik.current.setFieldValue(
            "cancellationTime",
            convertTimeStringToDate("00:00:00")
          );
          formik.current.setFieldValue("passenger", "");
          formik.current.setFieldValue("suitcases", "");
          formik.current.setFieldValue("rate", "0");
          formik.current.setFieldValue("applyToAll", false);
        }
      }
    } else {
      if (formik?.current) {
        // formik.current.setFieldValue(
        //   "leadTime",
        //   convertTimeStringToDate("00:00:00")
        // );
        formik.current.setFieldValue("leadTime", "00:00");
        formik.current.setFieldValue(
          "idleTime",
          convertTimeStringToDate("00:00:00")
        );
        formik.current.setFieldValue(
          "cancellationTime",
          convertTimeStringToDate("00:00:00")
        );
        formik.current.setFieldValue("passenger", "");
        formik.current.setFieldValue("suitcases", "");
        formik.current.setFieldValue("rate", "0");
        formik.current.setFieldValue("applyToAll", false);
      }
    }
  };
  const getInitialData = (selectServiceLevel) => {
    const getInitialValue = serviceLevelList?.filter(
      (df) => df.id === selectServiceLevel
    );

    if (
      getInitialValue.length > 0 &&
      getInitialValue[0]?.associatedServiceLevel.length
    ) {
      return getInitialValue[0]?.associatedServiceLevel[0];
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (getInitialData(defaultService)) {
      setInitialLoadData(getInitialData(defaultService));
    } else {
      setInitialLoadData("");
    }
  }, [defaultService]);

  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      className={"airport-service-level-edit-modal"}
      title={
        <>
          <div>
            <h3 className="font-17 font-500 ml-21 text-black">
              Edit Service Level
            </h3>
          </div>
        </>
      }
    >
      {/* Redesign Start */}
      <div className="pt-8 ml-21 mr-24">
        <Formik
          innerRef={formik}
          initialValues={{
            airportId: airportId,
            serviceLevelId: defaultService || "",
            leadTime: initialLoadData
              ? removeLast00(initialLoadData.leadTime)
              : "00:00",
            // leadTime: initialLoadData
            //   ? convertTimeStringToDate(initialLoadData.leadTime)
            //   : convertTimeStringToDate("00:00:00"),
            passenger: initialLoadData ? initialLoadData.passenger : "",
            suitcases: initialLoadData ? initialLoadData.suitcases : "",
            idleTime: initialLoadData
              ? convertTimeStringToDate(initialLoadData.idleTime)
              : convertTimeStringToDate("00:00:00"),
            rate: "0",
            cancellationTime: initialLoadData
              ? convertTimeStringToDate(initialLoadData.cancellationTime)
              : convertTimeStringToDate("00:00:00"),
            applyToAll: false,
          }}
          validationSchema={serviceLevelValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="">
                <div className="">
                  <div className="airport-service-level-fields">
                    <h3 className="m-0 mb-8 font-14 font-600 text-black">
                      Select Service Levels
                    </h3>
                    <Field
                      as="select"
                      name="serviceLevelId"
                      onChange={(e) => {
                        setInitialData(e.target.value);
                        setFieldValue("serviceLevelId", e.target.value);
                      }}
                    >
                      <option value="">Select Vehicle</option>
                      {serviceLevelList?.map((service) => (
                        <option key={service.id} value={service.id}>
                          {service?.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="serviceLevelId"
                      component="p"
                      className="text-danger font-10"
                    />
                  </div>

                  <div className="font-12 mt-15 flex">
                    <Field
                      id="applyToAll"
                      type="checkbox"
                      name="applyToAll"
                      checked={values.applyToAll}
                    />
                    <label htmlFor="applyToAll" style={{ marginLeft: 7 }}>
                      Apply to all service levels
                    </label>
                  </div>
                  <div className="flex gap-16 mt-24">
                    <div className="flex-1">
                      <p className=" m-0 font-12 font-400 mb-10 text-black">
                        Passengers
                      </p>
                      <div className="airport-service-level-fields ">
                        <Field
                          type="text"
                          id={`passenger`}
                          name={`passenger`}
                          placeholder="0"
                        />
                        <ErrorMessage
                          name="passenger"
                          component="p"
                          className="text-danger font-10"
                        />
                      </div>
                    </div>

                    <div className="flex-1">
                      <p className="font-12 font-400 mb-10 text-black">
                        Suitcases
                      </p>
                      <div className="airport-service-level-fields ">
                        <Field
                          type="text"
                          id={`suitcases`}
                          name={`suitcases`}
                          placeholder="0"
                        />{" "}
                        <ErrorMessage
                          name="suitcases"
                          component="p"
                          className="text-danger font-10"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-26">
                  <h3 className="font-14 font-600  text-black">Timing</h3>
                </div>

                <div className="flex align-item-center gap-16 mt-18">
                  <div className="flex-1">
                    <p className="font-12 font-400 mb-8 text-black">
                      Lead Time (Hours)
                    </p>
                    {/* <Field
                      name="leadTime"
                      className="airport-service-level-time-field"
                      component={TimePickerField}
                      value={values.leadTime}
                    /> */}
                    <Field
                      name="leadTime"
                      render={({ field, form }) => {
                        return (
                          <ClockTimer
                            field={field}
                            form={form}
                            style={{
                              height: "32px",
                            }}
                            iconStyle={{
                              height: "16px",
                              width: "16px",
                              cursor: "pointer",
                              marginRight: "4px",
                            }}
                          />
                        );
                      }}
                    />
                    <ErrorMessage
                      name="leadTime"
                      component="p"
                      className="text-danger font-10"
                    />
                  </div>
                  {/* Price (per Min) */}
                  <div
                    className="flex-1"
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        opacity: 0.6,
                        pointerEvents: "none",
                      }}
                    >
                      <p className="font-12 font-400 mb-8 text-black">
                        Cancellation Time (Hours)
                      </p>
                      <Field
                        name="cancellationTime"
                        className="airport-service-level-time-field"
                        component={TimePickerField}
                        value={values.cancellationTime}
                      />
                      <ErrorMessage
                        name="cancellationTime"
                        component="p"
                        className="text-danger font-10"
                      />
                    </div>

                    <ToolTipMessage
                      type="primary"
                      content={
                        <>
                          <p className="font-12 font-500">
                            This does not apply to Booking.com or Ctrip.com
                          </p>
                        </>
                      }
                    >
                      <div className="tooltip-icon-position">
                        <div>
                          <img
                            src={ExclamationIcon}
                            alt="ExclamationIcon"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </ToolTipMessage>
                  </div>
                  {/* Price (for Waiting) */}
                </div>
              </div>

              {/* Create Location Submit Button */}
              <div className="flex align-item-center justify-content-end mt-42">
                <MOButtonV2
                  type="submit"
                  text="SAVE EDIT"
                  textColor="#ffffff"
                  radius={4}
                  widthSize={137}
                  height={32}
                  padding={"10px 12px 10px 12px"}
                  backgroundColor="#29AEE6"
                  fontSize={11}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Redesign end */}
    </MOModalWrapper>
  );
};

export default ServiceLevelModal;
