import { RATE_MODULE } from "../../actions/local/constants/rateModule";
import rateModuleState, {
  RateModuleState,
} from "../../initial-state/rate-module";

const rateModuleReducer = (
  state: RateModuleState = rateModuleState,
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case RATE_MODULE.RESET_ALL: {
      return {
        ...rateModuleState,
      };
    }
    case RATE_MODULE.STATUS.VALUE: {
      const { field, status } = payload;
      return {
        ...state,
        [field]: status,
      };
    }
    case RATE_MODULE.STATUS.MESSAGE: {
      const { field,message } = payload;
      return {
        ...state,
        [field]: message,
      };
    }
    default:
      return state;
  }
};

export default rateModuleReducer;
