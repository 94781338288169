import { ErrorMessage, Field, Form, Formik } from "formik";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import {
  getTimeFormat24Hour,
  removeLast00,
} from "../../../../helpers/getTimeFormat";
import { toastMessage } from "../../../../helpers/toastMessage";
import mobioApi from "../../../../services";
import { campaignValidationSchema } from "../../../../validations/rateModuleValidation";
import DatePickerField from "../../../../components/commons/DatePickerField";
import ClockTimer from "../../../../components/commons/ClockTimer";
import { useDispatch } from "react-redux";
import ThunksRateModule from "../../../../redux/actions/thunks/rateModule";

const AddCampaignModal = ({
  token,
  apiCall,
  modalOpen,
  airportId,
  setModalOpen,
  serviceLevelsList,
  singleData,
}) => {
  const dispatch = useDispatch();
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={singleData ? "Edit Campaign" : "Add a new campaign"}
      className={"campaign-add-modal"}
    >
      <div className="p-20">
        <Formik
          initialValues={{
            differenceType: singleData ? singleData.differenceType : "",
            differencePercentage: singleData
              ? singleData.differencePercentage
              : "",
            serviceLevelId: singleData ? singleData.serviceLevelId : "",
            // startTime: singleData
            //   ? convertTimeStringToDate(singleData.startTime)
            //   : convertTimeStringToDate("00:00:00"),
            // endTime: singleData
            //   ? convertTimeStringToDate(singleData.endTime)
            //   : convertTimeStringToDate("00:00:00"),
            // startDate: singleData ? formatDate(singleData.startDate) : "",
            // endDate: singleData ? formatDate(singleData.endDate) : "",

            startTime: singleData
              ? removeLast00(singleData.startTime)
              : "00:00",
            endTime: singleData ? removeLast00(singleData.endTime) : "00:00",
            startDate: singleData ? new Date(singleData.startDate) : new Date(),
            endDate: singleData ? new Date(singleData.endDate) : new Date(),
            campaignName: singleData ? singleData.campaignName : "",
          }}
          validationSchema={campaignValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const payload = {
                serviceLevelId: values.serviceLevelId,
                airportId: airportId,
                campaignName: values.campaignName,
                startTime: getTimeFormat24Hour(values.startTime),
                startDate: values.startDate,
                endTime: getTimeFormat24Hour(values.endTime),
                endDate: values.endDate,
                status: singleData ? singleData.status : true,
                differenceType: values.differenceType,
                differencePercentage: values.differencePercentage,
              };
              const api = mobioApi(token);
              let response = null;
              if (singleData) {
                payload.campaignId = singleData?.id;
                response = await api.updateCampaign(payload);
              } else {
                response = await api.addCampaign(payload);
              }
              dispatch(ThunksRateModule.updateStatus(true));
              apiCall();
              setModalOpen(false);
              toastMessage("success", response?.data?.msg);
            } catch (error) {
              toastMessage(
                "error",
                error.response?.data?.msg || error.response.data.error
              );
            }
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div>
                {/* Type */}
                <div>
                  <h2 className="m-0 font-600 font-14 ">Type</h2>
                  <div className="flex mt-14 ">
                    <div>
                      <label className="flex align-item-center ">
                        <Field
                          type="radio"
                          name="differenceType"
                          value="Increase"
                          className="m-0"
                          checked={values.differenceType === "Increase"}
                        />
                        <p className="ml-12 font-12 font-500 m-0"> Increase</p>
                      </label>
                    </div>
                    <div
                      style={{
                        marginLeft: "90px",
                      }}
                    >
                      <label className="flex align-item-center">
                        <Field
                          type="radio"
                          name="differenceType"
                          value="Decrease"
                          className="m-0"
                          checked={values.differenceType === "Decrease"}
                        />
                        <p className="ml-12 font-12 font-500 m-0">Decrease</p>
                      </label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="differenceType"
                    component="p"
                    className="text-danger font-10"
                  />
                </div>

                {/* Enter an difference Percentage */}
                <div className="mt-30">
                  <h2 className="m-0 font-600 font-14 ">Enter an amount</h2>
                  <p className="m-0 font-12 font-400 text-black-gray-100 ">
                    This will be applied to the total price of an applicable
                    distance and fixed route rates.
                  </p>
                  <div className="w-142 mt-24">
                    <label className="">
                      <span className="font-14 font-500">Increase By</span>
                    </label>
                    <div className="input-field-increase-by mt-12">
                      <Field
                        type="number"
                        id={`differencePercentage`}
                        name={`differencePercentage`}
                        value={values.differencePercentage}
                      />

                      <p className="m-0">%</p>
                    </div>
                  </div>
                  <ErrorMessage
                    name="differencePercentage"
                    component="p"
                    className="text-danger font-10"
                  />
                </div>

                {/* Vehicle Types */}
                <div className="mt-30">
                  <h2 className="m-0 font-600 font-14">Vehicle Types</h2>
                  <div className="w-286 mt-10">
                    <div className="campaign-select">
                      <Field
                        as="select"
                        name="serviceLevelId"
                        className=""
                        value={values.serviceLevelId}
                      >
                        <option value="">Enter Your Vehicle Type</option>
                        {serviceLevelsList?.map((service) => {
                          return (
                            <>
                              <option key={service?.id} value={service?.id}>
                                {service.name}
                              </option>
                            </>
                          );
                        })}
                      </Field>

                      <ErrorMessage
                        name="serviceLevelId"
                        component="p"
                        className="text-danger font-10"
                      />
                    </div>
                  </div>
                </div>

                {/* Active Pick-up dates */}
                <div className="mt-30">
                  <h2 className="m-0 font-600 font-14">Active Pick-up dates</h2>
                  <div className=" mt-16">
                    <div className="flex align-item-center gap-32">
                      <div className="flex-1">
                        {/* Start Date */}
                        <div className="input_field">
                          <label className="font-12 mb-6">Start Date</label>
                          {/* <Field
                            type="date"
                            name="startDate"
                            style={{ textIndent: "4px" }}
                            value={values.startDate}
                          /> */}
                          <DatePickerField
                            name="startDate"
                            value={values?.startDate}
                            onChange={(value) =>
                              setFieldValue("startDate", value)
                            }
                          />
                          <ErrorMessage
                            name="startDate"
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        {/* End Date */}
                        <div className="input_field">
                          <label className="font-12 mb-6">End Date</label>
                          {/* <Field
                            type="date"
                            name="endDate"
                            style={{ textIndent: "4px" }}
                            value={values.endDate}
                          /> */}
                          <DatePickerField
                            name="endDate"
                            value={values?.startDate}
                            onChange={(value) =>
                              setFieldValue("endDate", value)
                            }
                          />
                          <ErrorMessage
                            name="endDate"
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex align-item-center gap-32 mt-12">
                      <div className="flex-1">
                        {/* Start time */}
                        <div className="">
                          <p className="font-14 mb-6">Start time</p>
                          {/* <Field
                            name="startTime"
                            className="pickup-time-picker"
                            component={TimePickerField}
                            value={values.startTime}
                          /> */}
                          <Field
                            name="startTime"
                            render={({ field, form }) => {
                              return (
                                <ClockTimer
                                  field={field}
                                  form={form}
                                  style={{
                                    height: "32px",
                                  }}
                                  iconStyle={{
                                    height: "16px",
                                    width: "16px",
                                    cursor: "pointer",
                                    marginRight: "4px",
                                  }}
                                />
                              );
                            }}
                          />
                          <ErrorMessage
                            name="startTime"
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        {/* End Time */}
                        <div className="">
                          <p className="font-14 mb-6">End time</p>
                          {/* <Field
                            name="endTime"
                            className="pickup-time-picker"
                            component={TimePickerField}
                            value={values.endTime}
                          /> */}
                          <Field
                            name="endTime"
                            render={({ field, form }) => {
                              return (
                                <ClockTimer
                                  field={field}
                                  form={form}
                                  style={{
                                    height: "32px",
                                  }}
                                  iconStyle={{
                                    height: "16px",
                                    width: "16px",
                                    cursor: "pointer",
                                    marginRight: "4px",
                                  }}
                                />
                              );
                            }}
                          />
                          <ErrorMessage
                            name="endTime"
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Name rate campaign */}
                <div className="mt-24">
                  <h2 className="m-0 font-600 font-14">
                    Name your rate campaign
                  </h2>
                  <div className="mt-16 width-half-46">
                    <div className="input_field">
                      <label className="font-12 mb-6">Name</label>
                      <Field
                        type="text"
                        name="campaignName"
                        placeholder="Enter Campaign Name"
                        value={values.campaignName}
                      />
                      <ErrorMessage
                        name="campaignName"
                        component="p"
                        className="text-danger font-10"
                      />
                    </div>
                  </div>
                  <p className="m-0 font-10 font-400 mt-8 text-black-gray-100">
                    This name is your reference, it won't be shown to customer
                  </p>
                </div>

                {/* save and cancel button */}
                <div className="flex align-item-center justify-content-end mt-24">
                  <div className="mr-20 ">
                    <MOButtonV2
                      type={"submit"}
                      text={singleData ? "EDIT CAMPAIGN" : "CREATE CAMPAIGN"}
                      textColor="#ffffff"
                      radius={4}
                      backgroundColor="#29AEE6"
                      height={30}
                      fontSize={11}
                      padding={"10px 28px"}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

export default AddCampaignModal;
