import Local from "../../local";

export type ThunksRateModuleListing = {
  updateStatus: (status: boolean) => any;
  updateMessage: (message: string) => any;
};

const ThunksRateModule: ThunksRateModuleListing = {
  updateStatus: (status) => (dispatch: any, getState: any, mobioApi: any) => {
    dispatch(Local.RateModule.status.statusValue("rateUpdateStatus", status));
  },
  updateMessage: (message) => (dispatch: any, getState: any, mobioApi: any) => {
    dispatch(
      Local.RateModule.status.statusMessage("rateStatusMessage", message)
    );
  },
};

export default ThunksRateModule;
