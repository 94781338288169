import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useNavigate } from "react-router-dom";
import { ChevronRight, KeyboardBackspace, Warning } from "@material-ui/icons";
import ToolTipMessage from "./commons/ToolTipMessage";
import { useSelector } from "react-redux";

const Breadcrumb = ({
  paths,
  secondary = false,
  backType = "manual",
  toolTipStatus = false,
}) => {
  const navigate = useNavigate();
  const [toolTipDetail, setTooltipDetail] = useState({
    status: false,
    message: null,
  });
  const { rateUpdateStatus, rateStatusMessage } = useSelector(
    (state) => state.rateModule.rateStateManage
  );
  const handlePathClick = (path) => {
    navigate(path.url);
  };

  useEffect(() => {
    if (toolTipStatus) {
      setTooltipDetail({
        status: rateUpdateStatus,
        message: rateStatusMessage,
      });
    }
  }, [rateUpdateStatus]);

  return (
    <div
      className={`breadcrumb ${toolTipStatus && "breadcrumb-tooltip-status"}`}
    >
      {!secondary && (
        <>
          <KeyboardBackspace
            style={{
              fontSize: 26,
              marginLeft: 6,
              cursor: "pointer",
              marginRight: 10,
              verticalAlign: "middle",
            }}
            onClick={() => {
              if (backType === "auto") {
                navigate(-1);
              } else {
                handlePathClick(paths[0]);
              }
            }}
          />
        </>
      )}

      {paths.map((path, index) => (
        <React.Fragment key={index}>
          {index !== paths.length - 1 ? (
            <span
              style={{ cursor: "pointer" }}
              className={
                index === paths.length - 1
                  ? `breadcrumb-current font-600 ${
                      secondary ? "font-14 " : " font-16"
                    }`
                  : `breadcrumb-link-blue font-600 ${
                      secondary ? "font-14 " : " font-16"
                    }`
              }
              onClick={() => handlePathClick(path)}
            >
              {path.name}{" "}
              <span>
                <ChevronRight
                  style={{
                    color: index === paths.length - 2 ? "#000000" : "#29AEE6",
                    verticalAlign: "middle",
                    width: "0.9em",
                    height: "0.9em",
                  }}
                />
              </span>{" "}
            </span>
          ) : (
            <span
              className={`breadcrumb-current-black  ${
                secondary ? "font-14 font-600" : " font-16"
              } `}
            >
              {path.name}
            </span>
          )}
        </React.Fragment>
      ))}

      {toolTipDetail?.status && (
        <>
          <div className="breadcrumb-tooltip">
            <ToolTipMessage
              type="primary"
              content={
                <>
                  <p className="font-12 font-500">{toolTipDetail?.message}</p>
                </>
              }
            >
              <div className="tab-selection-tooltip-icon">
                <div>
                  <Warning
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#ffc107",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </ToolTipMessage>
          </div>
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
