export const getDistanceUnit=(unit)=> {
    if(unit){
        const normalizedUnit = unit.toLowerCase();
        if (normalizedUnit === 'km') {
          return 'KM';
        } else if (normalizedUnit === 'miles' || normalizedUnit === 'mi') {
          return 'MI';
        } else {
          throw new Error('Invalid unit. Please provide "km" or "miles".');
        }
    }
  
  }
  
   